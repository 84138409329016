<template>
    <div class="row">
        <h3>{{ cursoActual.nombre_curso }}</h3>
        <a
            href="#"
            text="Descargar certificado"
            @click.prevent="downloadCert()" />
    </div>
    <div class="visor" style="width: 95%;">
        <div class="row">
            <div class="col-sm-12 col-md-8">
                <div class="row right-bar">
                    <div v-if="nombreVideo !== null" class="col-sm-12">
                        <div v-html="nombreVideo"></div>
                    </div>
                </div>
                <div class="row right-bar">
                    <span v-html="cursoActual.descripcion_curso"></span>
                </div>
            </div>
            <div class="col-sm-12 col-md-4 temas">
                <div class="row">
                    <router-link :to="link(id_curso)" class="btn btn-success">
                        <i class="bx bx-money"></i> Inscribirte por MXN {{cursoActual.costo}}
                    </router-link>
                </div>

                <div class="row">
                    <h6>Temas</h6>
                </div>

                <div class="accordion" id="accordionExample">
                    <div class="accordion-item" v-for="(modulo, indexModulo) in cursoActual.modulos" :key="indexModulo">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                :data-bs-target="idAcorddionTarget(indexModulo)" aria-expanded="true"
                                :aria-controls="idAcorddion(indexModulo)">
                                {{ modulo.nombre_modulo }}
                            </button>
                        </h2>
                        <div :id="idAcorddion(indexModulo)" class="accordion-collapse collapse show"
                            aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <div class="row" v-for="(lectura, indexLectura) in modulo.lecturas" :key="indexLectura">
                                    <div class="col-sm-12">
                                        <span>{{ lectura.nombre_lectura }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <router-link :to="link(id_curso)" class="btn btn-success">
                        <i class="bx bx-money"></i> Inscribirte por MXN {{cursoActual.costo}}
                    </router-link>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'PrevioCursoGuest',
    data() {
        return {
            id_curso: 0,
            cursoActual: {},
            contenidoVisor: null,
            nombreVideo: null,
            nombreLectura: null,
            nombreModulo: null,
            pathVideo: null,
            tipo: '',
            lectura: {},
            lecturaActual: 0,
            indexModuloActual: 0,
            indexLecturaActual: 0,
            files:[],
        }
    },
    mounted() {
        let loaderCursoActual = this.$loading.show({ canCancel: false })
        if (!localStorage.webToken) {
            axios
                .get(this.$backendUrl + '/devices/token?device=' + this.$backendDevice)
                .then(response => {
                    console.log(response.data.token)
                    localStorage.webToken = response.data.token
                    window.location.reload()
                })
        }
        if (this.$route.query.id !== undefined) {
            this.id_curso = atob(this.$route.query.id)
        }
        axios
            .get(this.$backendUrl + '/global/curso?id=' + this.id_curso, { headers: { token: localStorage.webToken } })
            .then(response => {
                this.cursoActual = response.data.data
                this.lectura = this.cursoActual.modulos[0].lecturas[0]
                let lectura = this.cursoActual.modulos[0].lecturas[0]
                this.contenidoVisor = lectura.contenido_hmtl
                this.nombreVideo = lectura.nombre_video
                this.pathVideo = lectura.nombre_video + '?autoplay=true&loop=false&muted=false&preload=true&responsive=true' // this.$backendUrl + '/video?path=' + this.cursoActual.identificador + '&id=' + lectura.nombre_video
                this.tipo = lectura.tipo
                this.nombreModulo = this.cursoActual.modulos[0].nombre_modulo
                this.nombreLectura = lectura.nombre_lectura
                loaderCursoActual.hide()
            }, error => {
                console.log(error.response.data.description)
                loaderCursoActual.hide()
            })
    },
    methods: {
        downloadCert() {
            axios
                .get(this.$backendUrl + '/pdf/certificado')
                .then(response => {
                    const blob = new Blob([response.data], { type: 'application/pdf' })
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = 'certificado'
                    link.click()
                    URL.revokeObjectURL(link.href)
                })
        },
        idAcorddion(id) {
            return 'modulo' + id;
        },
        idAcorddionTarget(id) {
            return '#modulo' + id;
        },
        link: function (id) {
            return '/checkout?id=' + btoa(id)
        },
    },
}

</script>

<style lang="scss">
.visor {
    background-color: #fff;
    width: 95%;
    padding: 10px 20px;
    border-radius: 10px;
    .modulo {
        font-weight: bolder;
        font-size: 16px;
    }
    .temas {
        > .row {
            padding: 5px 5px;
        }
        .contenido {
            padding: 5px 10px;
            .row {
                padding: 8px 15px;
            }
        }
        background-color: #eee;
        span {
            font-size: 14px;
            padding: 3px;
        }
    }
}
</style>
