<template>
    <div class="widgets-area">
        <div class="container-fluid" v-if="id_curso">
            <div class="row">
                <div class="card-box-style col-md-6">
                    <div class="row justify-content-center">
                        <h6>Te vas a inscribir a:</h6>
                        <p><strong>{{ identificador }} - {{nombreCurso}}</strong>.</p>
                        <p>{{descripcionCorta}}</p>
                    </div>
                </div>
                <div class="card-box-style col-md-3" style="margin-left: 10px;">
                    <div class="row justify-content-center">
                        <p>Total a pagar MXN <strong>{{ amount }}</strong></p>
                        <div v-if="!this.cuponAplicado">
                            <input type="text" class="form-control" v-model="cupon" placeholder="Cupón de descuento" />
                            <button class="btn btn-primary mb-1 me-1" @click="descuento">Aplicar cupón</button>
                        </div>
                        <div v-if="this.cuponAplicado">
                            <p>Descuento aplicado por cupón {{ cupon }}</p>
                            <button class="btn btn-danger mb-1 me-1" @click="borrarCupon">Quitar cupón</button>
                        </div>
                        <br>
                        <p>Haciendo clic en el botón de abajo serás redireccionado al formulario de pago,
                            por favor no cierres esta ventana hasta que veas una pantalla con el resultado del proceso.</p>
                        <button class="btn btn-success mb-1 me-1" @click="submit">Pagar!</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
//import moment from 'moment-timezone'

export default {
    name: 'CheckoutContent',
    data() {
        return {
            paymentData: {},
            cursoActual: {},
            nombreCurso: '',
            identificador: '',
            descripcionCorta: '',
            cupon: '',
            amount: 0,
            id_curso: 0,
            cuponAplicado: false,
            montoDescuento: 0,
            costoCurso: 0,
        }
    },
    mounted() {
        if (this.$route.query.id !== undefined) {
            this.id_curso = atob(this.$route.query.id)
            localStorage.lastCheckoutId = false
            let loaderCurso = this.$loading.show({ canCancel: false })
            axios
                .get(this.$backendUrl + '/global/curso?id=' + this.id_curso, { headers: { token: localStorage.userToken } })
                .then(response => {
                    this.cursoActual = response.data.data
                    this.costoCurso = this.cursoActual.costo
                    this.nombreCurso = this.cursoActual.nombre_curso
                    this.identificador = this.cursoActual.identificador
                    this.descripcionCorta = this.cursoActual.descripcion_curso_corta
                    this.amount = this.costoCurso
                    loaderCurso.hide()
                }, error => {
                    this.$swal({ icon: 'error', text: error.response.data.description })
                    loaderCurso.hide()
                })
        } else {
            this.$swal({ icon: 'error', text: 'Error al obtener los datos del curso' })
        }
    },
    methods: {
        descuento() {
            let loaderPago = this.$loading.show({ canCancel: false })
            axios
                .post(this.$backendUrl + '/payment/descuento', { amount: this.amount, curso: this.id_curso, cupon: this.cupon }, { headers: { token: localStorage.userToken } })
                .then(response => {
                    this.cuponAplicado = true
                    this.montoDescuento = this.amount * (response.data.data.porcentaje / 100)
                    this.amount = this.costoCurso - this.montoDescuento
                    console.log(response.data.data)
                    loaderPago.hide()
                }, error => {
                    console.log(error.response.data.description)
                    loaderPago.hide()
                })
        },
        borrarCupon() {
            this.cuponAplicado = false
            this.cupon = ''
            this.montoDescuento = 0
            this.amount = this.costoCurso
        },
        link: function (id) {
            return '/cursos/previo?id=' + btoa(id)
        },
        submit() {
            let loaderCurso = this.$loading.show({ canCancel: false })
            axios
                .post(this.$backendUrl + '/payment/session',
                    {
                        amount: this.amount + '00',
                        currency: 'mxn',
                        productName: this.identificador + ' | ' + this.nombreCurso,
                        query: btoa(this.id_curso + '|' + this.cupon + '|' + this.amount)
                    },
                    { headers: { token: localStorage.userToken } }
                )
                .then(response => {
                    this.paymentData = response.data
                    localStorage.lastCheckoutId = this.paymentData.id
                    window.location.href = this.paymentData.url
                    loaderCurso.hide()
                }, error => {
                    console.log(error.response.data.description)
                    loaderCurso.hide()
                })
        },
    },
}
</script>

<style lang="scss">
.curso-box {
    padding-top: 1em;
}

.card-box {
    h5 {
        font-weight: 500;
        font-size: 20px;
    }
}

.card-statics {
    .badge {
        background-color: #1765fd;
    }
    font-size: 12px;
}
</style>