<template>
    <div class="all-section-area">
        <main class="main-content-wrap">
            <PrevioCursoGuest />
            <FooterArea />
        </main>
    </div>
</template>

<script>
import PrevioCursoGuest from '../Cursos/PrevioCursoGuest'
import FooterArea from '../Layouts/FooterArea'

export default {
    name: 'PrevioCursoGuestPage',
    components: {
        PrevioCursoGuest,
        FooterArea,
    },
    mounted() {
        document.body.classList.add('body-bg-f8faff')
    },
}
</script>

<style lang="scss">
.main-content-wrap {
    padding-top: 80px !important;
    padding-left: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
}
</style>
