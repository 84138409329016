<template>
    <div class="widgets-area">
        <div class="container-fluid" v-if="id_curso">
            <div class="row">
                <div class="card-box-style col-md-6">
                    <div class="row justify-content-center">
                        <h6>Felicidades te inscribiste al curso</h6>
                        <p><strong>{{ identificador }} - {{nombreCurso}}</strong>.</p>
                    </div>
                </div>
                <div class="card-box-style col-md-3" style="margin-left: 10px;">
                    <div class="row justify-content-center">
                        <router-link to="/cursos/mis-cursos" class="btn btn-success">
                            Haz clic aquí para comenzar
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
//import moment from 'moment-timezone'

export default {
    name: 'CheckoutSuccessContent',
    data() {
        return {
            nombreCurso: '',
            identificador: '',
            id_curso: 0,
            cupon: '',
            lastCheckoutId: '',
        }
    },
    mounted() {
        if (this.$route.query.data !== undefined) {
            let query = atob(this.$route.query.data)
            let dataPayment = query.split('|')
            this.id_curso = dataPayment[0]
            this.cupon = dataPayment[1]
            this.lastCheckoutId = localStorage.lastCheckoutId
            if (this.lastCheckoutId != '') {
                let loaderCurso = this.$loading.show({ canCancel: false })
                axios
                    .get(this.$backendUrl + '/global/curso?id=' + this.id_curso, { headers: { token: localStorage.userToken } })
                    .then(response => {
                        this.nombreCurso = response.data.data.nombre_curso
                        this.identificador = response.data.data.identificador
                        axios
                            .post(this.$backendUrl + '/payment/suscripcion', {
                                identificador_pago: localStorage.lastCheckoutId,
                                id_curso: this.id_curso,
                                proveedor: 'stripe',
                                id_plan: 0,
                            }, { headers: { token: localStorage.userToken } })
                            .then(response => {
                                console.log(response)
                                if (this.cupon != '') {
                                    axios
                                        .post(this.$backendUrl + '/payment/cupon-aplicado', { cupon: this.cupon, id_curso: this.id_curso }, { headers: { token: localStorage.userToken } })
                                        .then(response => {
                                            console.log(response)
                                            loaderCurso.hide()
                                        }, error => {
                                            this.$swal({ icon: 'error', text: error.response.data.description })
                                            loaderCurso.hide()
                                        })
                                } else {
                                    loaderCurso.hide()
                                }
                                axios
                                    .post(this.$backendUrl + '/payment/suscripcion-email', {
                                        nombreCurso: this.nombreCurso,
                                        usuario: JSON.parse(localStorage.user),
                                    }, { headers: { token: localStorage.userToken } })
                                    .then(response => {
                                        console.log(response)
                                    })
                                localStorage.lastCheckoutId = ''
                            }, error => {
                                console.log(error.response.data)
                                this.$swal({ icon: 'error', text: error.response.data.data.description })
                                loaderCurso.hide()
                            })
                    }, error => {
                        this.$swal({ icon: 'error', text: error.response.data.description })
                        loaderCurso.hide()
                    })
            } else {
                this.id_curso = 0
                this.$swal({ icon: 'error', text: 'Se ha caducado el proceso de pago.' })
            }
        }
    },
    methods: {
        link: function (id) {
            return '/cursos/previo?id=' + btoa(id)
        },
    },
}
</script>

<style lang="scss">
.curso-box {
    padding-top: 1em;
}

.card-box {
    h5 {
        font-weight: 500;
        font-size: 20px;
    }
}

.card-statics {
    .badge {
        background-color: #1765fd;
    }
    font-size: 12px;
}
</style>